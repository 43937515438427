export default {
    dynamic: {
        1: '<p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/article/1/1.jpg"></p><p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/article/1/2.jpg"></p><p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/article/1/3.jpg"></p>',
        2: '<p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/article/2/1.jpg"></p><p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/article/2/2.jpg"></p><p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/article/2/3.jpg"></p>',
        3: '<p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/article/3/1.jpg"></p>',
        4: '<p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/article/4/1.jpg"></p><p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/article/4/2.jpg"></p>',
        5: '<p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/article/5/1.jpg"></p>',
        6: '<p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/article/6/1.jpg"></p><p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/article/6/2.jpg"></p><p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/article/6/3.jpg"></p><p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/article/6/4.jpg"></p><p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/article/6/5.jpg"></p><p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/article/6/6.jpg"></p>',
        7: '<p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/article/7/1-min.jpg"></p><p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/article/7/2-min.jpg"></p>',
        8: '<p class="ql-align-center"><img src="require(`./new_img_static/img_1.jpg"`)"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/article/8/2.jpg"></p><p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/article/8/3.jpg"></p>'
    },
    data: [
        {
            id: 1,
            image: [
                {url:('https://public-oss.sbznck.com/shibotong-owev2-website/image/article/1/1.jpg')},
                {url:('https://public-oss.sbznck.com/shibotong-owev2-website/image/article/1/2.jpg')}
            ] 
        },
        {
            id: 2,
            image: [
                {url:('https://public-oss.sbznck.com/shibotong-owev2-website/image/article/2/1.jpg')},
                {url:('https://public-oss.sbznck.com/shibotong-owev2-website/image/article/2/2.jpg')}
            ] 
        },
        {
            id: 3,
            image: [
                {url:('https://public-oss.sbznck.com/shibotong-owev2-website/image/article/3/1.jpg')},
                {url:('https://public-oss.sbznck.com/shibotong-owev2-website/image/article/3/2.jpg')}
            ] 
        },
        {
            id: 4,
            image: [
                {url:('https://public-oss.sbznck.com/shibotong-owev2-website/image/article/4/1.jpg')},
                {url:('https://public-oss.sbznck.com/shibotong-owev2-website/image/article/4/2.jpg')}
            ] 
        },
        {
            id: 5,
            image: [
                {url:('https://public-oss.sbznck.com/shibotong-owev2-website/image/article/5/1.jpg')},
                {url:('https://public-oss.sbznck.com/shibotong-owev2-website/image/article/5/2.jpg')}
            ] 
        },
        {
            id: 6,
            image: [
                {url:('https://public-oss.sbznck.com/shibotong-owev2-website/image/article/6/1.jpg')},
                {url:('https://public-oss.sbznck.com/shibotong-owev2-website/image/article/6/2.jpg')}
            ] 
        },
        {
            id: 7,
            image: [
                {url:('https://public-oss.sbznck.com/shibotong-owev2-website/image/article/7/1-min.jpg')},
                {url:('https://public-oss.sbznck.com/shibotong-owev2-website/image/article/7/2-min.jpg')}
            ] 
        },
        {
            id: 8,
            image: [
                {url:require('@/assets/image/new_static_party/img_1.jpg')},
                {url:require('@/assets/image/new_static_party/img_2.jpg')},
                {url:require('@/assets/image/new_static_party/img_5.jpg')},
                {url:require('@/assets/image/new_static_party/img_4.jpg')}
            ] 
        },
    ],


    product: {
        1: '<p class="ql-align-center">我司研发的智能汽车搬运AGV机器人，采用最先进的三维多线激光导航技术，运用多电机联合动作控制，具有独到的车体设计，适配95%以上的车型的底盘高度和重量，能实现自动找车、抬车、放车，可在无辅助设备、无固定轨道、无可见光环境下，完成汽车存取车辆过程，同时具备避障能力，切实保障客户车辆和周边环境安全。</p><p class="ql-align-center"><br></p><p class="ql-align-center"><br></p><p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/product/1/1.jpg"></p><p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/product/1/2.jpg"></p><p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/product/1/3.jpg"></p>'
    },
    solution: {
        1: '<p>AGV智能立体停车库，采用垂直大容量高速电梯和防火楼层板及隔板，具有容量大、速度快、噪音小、故障率低、安全可靠等优点，首层具有独到的管理中控室和司机休息室等人性化设计，存取车时，客户只需通过微信小程序即可完成停车取车。</p><h3><br></h3><h3 class="ql-align-center">车库外观</h3><p></p><p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/solution/1/1.jpg"></p><p class="ql-align-center"><br></p><h3 class="ql-align-center">内部车辆升降</h3><p class="ql-align-center"><br></p><p class="ql-align-center"></p><p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/solution/1/2.jpg"></p><p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/solution/1/3.jpg"></p><p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/solution/1/4.jpg"></p><p class="ql-align-center"><img src="https://public-oss.sbznck.com/shibotong-owev2-website/image/solution/1/5.jpg"></p>'
    }
}

// 生成文章
// http://localhost:8080/edit