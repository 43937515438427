import { render, staticRenderFns } from "./article.vue?vue&type=template&id=31418f66&scoped=true&"
import script from "./article.vue?vue&type=script&lang=js&"
export * from "./article.vue?vue&type=script&lang=js&"
import style0 from "./article.vue?vue&type=style&index=0&id=31418f66&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31418f66",
  null
  
)

export default component.exports