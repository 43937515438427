<!-- eslint-disable vue/no-template-key -->
<!-- eslint-disable vue/require-v-for-key -->
<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <div class="atcover">
      <div class="CommonContentWidth">
        <div class="box">
          <div class="title">{{ index_item.title }}</div>
          <div class="info">{{ index_item.info }}</div>
        </div>

        <div class="cover"><img :src="index_item.image" /></div>
      </div>
    </div>

    <div class="CommonContentWidth">
      <div class="ql-snow content">
        <div class="ql-editor" v-for="(value, index) in articledata" :key="index">
          <template v-for="(value_img, index_img) in value" >
            <p class="ql-align-center">
              <img
                :src="value_img"
                alt=""
                width="100%"
              />
              <!-- {{ value_img}} -->
      
            </p>
          </template>
          <!-- {{ value }} -->
        </div>
      </div>
    </div>

    <!-- <vue-editor v-model="editorvmodel" v-show="0"></vue-editor> -->
  </div>
</template>

<script>
// @ is an alias to /src
import articleIndex from "../../data/articleIndex";
import article from "../../data/article";
// import { VueEditor } from 'vue2-editor'
export default {
  name: "CaseShows",
  components: {
    // VueEditor
  },
  data() {
    return {
      index_item: null,
      articleIndex: [],
      article: [],
      title: null,
      id: null,
      articledata: null,
    };
  },
  created() {
    this.articleIndex = articleIndex;
    this.article = article;
    this.id = this.$route.params.id;
    this.search_index(this.id);
    // console.error("dddd")
  },
  methods: {
    search_index(ids) {
      this.articleIndex.dynamic.forEach((item, index) => {
        // console.log(item)
        if (item.id == parseInt(ids)) {
          this.index_item = item;
          this.title = item.title;
          this.search_data(item.id);
        }
        index;
        // console.log(index)
      });
    },
    search_data(ids) {
      this.articledata = article.data[ids - 1].image;
      console.log(article.data[ids], ids);
    },
  },
};
</script>
<style lang="scss" scoped>
.atcover {
  padding-bottom: 40px;
  border-bottom: 1px solid #cecece;
  margin-top: 150px;
  display: flow-root;
  padding-bottom: 100px;
  .box {
    margin-top: 50px;
    float: left;
  }
  .title {
    font-size: 24px;
  }
  .info {
    font-size: 14px;
    margin-top: 10px;
  }
  .cover {
    float: right;
  }
}

.content {
  margin-bottom: 200px;
  margin-top: 50px;
}
</style>
